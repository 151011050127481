import React, { useState } from 'react';
import { Typography } from 'components/ui'
import { SBlock, SSaving } from './_styles.jsx'
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

//const createSliderWithTooltip = Slider.createSliderWithTooltip;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={`$${value}`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} handle={handle}/>
    </Tooltip>
  );
};
const Handle = Slider.Handle;

const DoCostSaverSection = () => {

  const [ value, setValue ] = useState(250);
  return (
    <SBlock>
      <Typography tag="h3">
        You could save up to ~<SSaving>${computeSaving(value)}<span>/month</span></SSaving>
        <Typography tag="p" className="font-light mt-2 text-xs">
          using SimpleBackups
        </Typography>
      </Typography>
      <div className="p-8">
        <Slider min={5} step={5} max={2500} value={value} handle={handle} onChange={newValue => setValue(newValue)}/>
      </div>
      <Typography tag=""  variant="large">DigitalOcean server(s) cost: <span className="font-bold">${value}<span className="text-xs">/month</span></span></Typography>
    </SBlock>
  )
}

const computeSaving = (serverCost) => {
  const val = Math.round(serverCost * 0.2);
  return (val <= 0) ? '-' : val
}

export default DoCostSaverSection
