import React from "react"
import styled from 'styled-components'
import { Link as LinkBase } from "gatsby"
import {theme} from '../../../tailwind.config'
import tw from "tailwind.macro"

const SPersonaGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align:center;
  ${tw`pl-8 pr-8 w-full`}
  > a {
    transition: 200ms ease-out;
  }
  > a:hover {
    color: white;
    text-decoration: none;
    transform: translate3d(0,-0.5rem,0)
  }

  > * {
    width: 30%;
    ${tw`pt-6 pb-6`}
    border-radius: 60px;
    color: white;

    > i {
      display: block;
      font-size: 30px;
      ${tw`pb-2`}
    }
  }
  
  @media(max-width: ${theme.screens.md.max}) {
    flex-wrap: wrap;
    > *{
      width: 80%;
      ${tw`pt-6 pb-6`}
      ${tw`mb-8`}
    }
  }
`

const Personas = () => {
  return (
    <SPersonaGrid>
    <LinkBase to="/startup-backup-solution/" style={{background: theme.colors.pink}}>
      <i className="fad fa-unicorn"/>
      Startups
    </LinkBase>
    <LinkBase to="/agency-backup-solution/" style={{background: theme.colors.blue}}>
      <i className="fad fa-users"/>
      Agencies
    </LinkBase>
    <LinkBase to="/developer-backup-solution/" style={{background: theme.colors.aubergine}}>
      <i className="fad fa-laptop-code"/>
      Developers
    </LinkBase>
  </SPersonaGrid>
  )
}

export default Personas


