import React from 'react'
import { FeatureGrid, Feature, }  from 'components/ui'

export default () => (
  <FeatureGrid cols="3" className="mt-8">
    <Feature
      icon={<i className="fa fa-rabbit-fast" />}
      title="Simple & quick setup"
      content="Use our cutting edge one-command method and get your backups configured in less than a minute."
      boxed
    />
    <Feature
      icon={<i className="fa fa-calendar-alt" />}
      title="Flexible backup schedule"
      content="Have your backups scheduled hourly, daily, weekly, monthly, or down to the minute and keep as many archives as you need."
      boxed
    />
    <Feature
      icon={<i className="fa fa-cloud" />}
      title="Bring your own storage"
      content="Store your backups on your own Digital Ocean, AWS, Backblaze, Google, Wasabi, Filebase, Dropbox account.
      Your backups won't even pass by our servers."
      boxed
    />
  </FeatureGrid>
)