import styled from 'styled-components'
import {theme} from '../../../../tailwind.config'
import tw from "tailwind.macro"

export const SBlock = styled.div`
  ${tw`p-10`}
  border-radius: 6px;
  max-width: 450px;
  background: white;
  text-align: center;
  box-shadow: 0 10px 35px 0 rgba(0,0,0,0.10);
  > p {
    font-weight: 100;
  }

  .rc-slider-track {
    background-color: ${theme.colors.primary};
  }
  .rc-slider-handle, .rc-slider-handle:hover, .rc-slider-handle:active, .rc-slider-handle:focus {
    border-color: ${theme.colors.primary};
    width: 24px;
    height: 24px;
    margin-top: -11px
  }
`

export const SSaving = styled.span`
  ${tw`text-primary`}
  > span {
    ${tw`text-sm`}
  }
  @media(max-width: ${theme.screens.md.max}) {
    ${tw`pl-8 pr-8 w-full`}
  }
`
